/*
 * Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
 * the trix-editor content (whether displayed or under editing). Feel free to incorporate this
 * inclusion directly in any other asset bundle and remove this file.
 *
 *= require trix
*/

/*
 * We need to override trix.css's image gallery styles to accommodate the
 * <action-text-attachment> element we wrap around attachments. Otherwise,
 * images in galleries will be squished by the max-width: 33%; rule.
*/
.trix-content .attachment-gallery>action-text-attachment,
.trix-content .attachment-gallery>.attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2>action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2>.attachment,
.trix-content .attachment-gallery.attachment-gallery--4>action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4>.attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}

/* Mention styling */
.trix-content .mention-wrapper {
  display: inline-flex;
  align-items: center;
}

/* Style for mentions in the editor (before being processed) */
.trix-content .mention {
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: rgba(5, 150, 105, 0.1);
  color: rgb(4, 120, 87);
  gap: 0.25rem;
}

/* Ensure the avatar in mentions has the right styling */
.trix-content .mention .avatar,
.trix-content .mention-wrapper .avatar {
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
}