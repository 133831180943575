@import "application.css";
@import "colors.css";
@import "teams/checkins.css";
@import "trix";
@import "actiontext.css";
@import "choices.js/public/assets/styles/choices.min.css";
@import "tom-select/dist/css/tom-select.css";
@import 'actiontext.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* Team Selector Dropdown Styles */
  .dropdown-content .menu {
    @apply w-full;
  }

  .dropdown-content .menu li>ul {
    @apply ml-4;
  }

  .dropdown-content .menu li>ul>li>ul {
    @apply ml-4;
  }

  .dropdown-content .menu a {
    @apply flex items-center gap-2 rounded-lg px-2 py-2 hover:bg-base-200;
  }

  .dropdown-content .menu a.active {
    @apply bg-base-200;
  }

  /* Essential OKR Grid */
  .okr-grid {
    @apply grid items-center gap-2;
    grid-template-columns: 2rem 2.5rem 1fr auto 8rem 8rem 8rem;
  }

  .okr-row {
    @apply bg-base-100 rounded-lg px-4 py-2.5 mb-2 transition-all duration-300 ease-out border border-base-200/10 hover:border-base-300/20 hover:shadow-sm hover:bg-base-100/80;
  }

  .okr-icon-container {
    @apply flex items-center justify-center;
  }

  /* Drag handle container with add button */
  .drag-handle-container {
    @apply flex items-center gap-1 justify-start w-8;
  }

  /* Drag handle with improved interaction */
  .drag-handle {
    @apply opacity-0 transition-all duration-200 cursor-grab text-base-content/30 hover:text-primary/70 hover:scale-110 active:cursor-grabbing active:text-primary flex items-center justify-center;
  }

  .okr-row:hover .drag-handle {
    @apply opacity-100;
  }

  /* Add button styling - Notion-like */
  .add-child-button {
    @apply opacity-0 transition-all duration-200 text-base-content/30 hover:text-primary/70 hover:scale-110 flex items-center justify-center w-6 h-6 rounded-sm hover:bg-base-200/50;
  }

  .okr-row:hover .add-child-button {
    @apply opacity-100;
  }

  .okr-nested:empty .add-child-button {
    @apply opacity-100;
  }

  /* Empty state add button */
  .keyresults-container:empty .add-child-button,
  .initiatives-container:empty .add-child-button {
    @apply opacity-100;
  }

  /* Dragging state with modern elevation */
  .dragging {
    @apply shadow-lg shadow-primary/5 bg-base-100 border-primary/30 !opacity-95 cursor-grabbing pointer-events-none ring-1 ring-primary/20;
    transform: scale(1.01);
  }

  /* Drop target indication with animation */
  .okr-row.drop-target-above {
    @apply relative;
  }

  .okr-row.drop-target-above::before {
    content: '';
    @apply absolute -top-1 left-2 right-2 h-0.5 bg-primary/60;
  }

  .okr-row.drop-target-below {
    @apply relative;
  }

  .okr-row.drop-target-below::after {
    content: '';
    @apply absolute -bottom-1 left-2 right-2 h-0.5 bg-primary/60;
  }

  /* Empty container styling with modern feedback */
  .keyresults-container:empty,
  .initiatives-container:empty {
    @apply min-h-[4.5rem] border border-dashed border-base-200/40 rounded-lg my-2 transition-all duration-300 ease-out bg-gradient-to-b from-transparent to-base-200/5;
  }

  /* Empty container drag-over state */
  .keyresults-container:empty.drag-over,
  .initiatives-container:empty.drag-over {
    @apply border-primary/40 border-solid bg-gradient-to-b from-primary/5 to-primary/10;
  }

  /* Nested content indentation with visual line */
  .okr-nested {
    @apply ml-8 relative pl-4;
  }

  .okr-nested::before {
    content: '';
    @apply absolute left-0 top-1 bottom-1 w-px bg-base-300/20;
  }

  /* Initiative specific nesting */
  .okr-initiative {
    @apply ml-8;
  }

  .okr-initiative::before {
    @apply left-[-1rem];
  }

  /* Header styling */
  .okr-header {
    @apply border-b border-base-300 bg-base-100 px-4 py-2 text-sm font-medium text-gray-500;
  }

  /* Icon colors with subtle glow */
  .okr-objective-icon {
    @apply text-blue-500/90 transition-colors duration-300;
  }

  .okr-keyresult-icon {
    @apply text-orange-500/90 transition-colors duration-300;
  }

  .okr-row:hover .okr-objective-icon {
    @apply text-blue-500;
  }

  .okr-row:hover .okr-keyresult-icon {
    @apply text-orange-500;
  }

  /* Responsive */
  @media (max-width: 768px) {
    .okr-grid {
      grid-template-columns: 2rem 2.5rem 1fr auto 8rem 8rem;
    }

    .okr-grid> :last-child {
      @apply hidden;
    }
  }

  /* Mention styles */
  .mention {
    @apply inline-flex items-center px-1 rounded bg-emerald-50 text-emerald-600 font-medium hover:text-emerald-700 hover:bg-emerald-100 cursor-pointer transition-colors duration-150;
  }

  .mention-suggestion {
    @apply w-full text-left;
  }

  .mention-suggestions {
    @apply absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg overflow-hidden;
  }

  /* Trix mention styles */
  .trix-content .mention {
    @apply no-underline;
  }

  .trix-content .attachment--content .mention {
    @apply inline-flex;
  }

  /* Hoverable popover positioning */
  .hoverable-popover {
    /* Default positioning for normal context */
    left: 0;
    top: 100%;
  }

  /* Sidebar-specific styling that breaks out of overflow constraints */
  [data-sidebar-target="sidebar"] .hoverable-popover {
    position: fixed;
    z-index: 999;
    transform: translateY(0);
    /* Calculate positions based on the client rect coordinates */
    top: var(--hover-top, 0);
    left: var(--hover-left, 0);
    transform-origin: top left;
  }

  /* CSS-only position calculation with custom properties */
  [data-sidebar-target="sidebar"] .group\/hoverable {
    /* Create a position reference point */
    position: relative;
  }

  /* Create a pseudo-element that follows the cursor and captures the position */
  [data-sidebar-target="sidebar"] .group\/hoverable::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  /* Position the popover relative to the hover position */
  [data-sidebar-target="sidebar"] .group\/hoverable:hover .hoverable-popover {
    display: block !important;
    /* Calculate an approximate position that works across most cases */
    --hover-top: calc(100% + 30px);
    --hover-left: 0;
  }

  /* When the sidebar is visible, make sure the popover appears above all content */
  [data-sidebar-target="sidebar"]:not(.hidden) .hoverable-popover {
    z-index: 9999;
  }

  /* When near right edge of viewport, align to right instead of left */
  @media (min-width: 640px) {
    .hoverable-popover {
      min-width: 280px;
    }

    /* Fallback for browsers without :has support */
    .activity-item:last-child .hoverable-popover,
    .activity-item:nth-last-child(2) .hoverable-popover {
      left: auto;
      right: 0;
    }

    /* Fixed sidebar positioning at right edge */
    [data-sidebar-target="sidebar"] .activity-item:last-child .hoverable-popover,
    [data-sidebar-target="sidebar"] .activity-item:nth-last-child(2) .hoverable-popover {
      /* Use right alignment from right edge of viewport */
      right: 1rem;
      left: auto;
    }

    @supports (selector(:has(body))) {
      body:has(.hoverable-popover:hover) {
        overflow: hidden;
      }

      .group\/hoverable:has(.hoverable-popover) {
        position: relative;
      }

      /* Right-side alignment when near right viewport edge */
      .group\/hoverable:has(.hoverable-popover):nth-last-child(-n+2) .hoverable-popover {
        left: auto;
        right: 0;
      }
    }
  }

  /* When near bottom of viewport, position above instead of below */
  @media (max-height: 700px) {
    .group\/hoverable:hover .hoverable-popover {
      top: auto;
      bottom: 100%;
    }

    .hoverable-popover .mt-2 {
      @apply mb-2 mt-0;
    }

    /* For fixed sidebar popovers near bottom */
    [data-sidebar-target="sidebar"] .group\/hoverable:hover .hoverable-popover {
      --hover-top: auto;
      --hover-bottom: calc(100% + 10px);
      bottom: var(--hover-bottom, auto);
      top: auto;
    }
  }

  /* Additional fixes for popover content when in fixed position */
  [data-sidebar-target="sidebar"] .hoverable-popover .bg-base-200 {
    /* Apply specific styling for the popover content in sidebar */
    border: 1px solid var(--fallback-base-300, hsl(var(--b3)));
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  }

  /* Fix for dark mode */
  [data-theme="dark"] [data-sidebar-target="sidebar"] .hoverable-popover .bg-base-200 {
    border-color: var(--fallback-base-content-10, hsl(var(--bc) / 0.1));
  }

  /* Fix for edge cases where the popover might get clipped by the viewport edge */
  @media (max-width: 640px) {
    [data-sidebar-target="sidebar"] .hoverable-popover {
      max-width: calc(100vw - 2rem);
      max-height: calc(100vh - 4rem);
      overflow-y: auto;
    }
  }
}

/* Hide less important columns when sidebar is open */
body:has([data-sidebar-target="sidebar"]:not(.hidden)) .okr-grid {
  grid-template-columns: 2rem 2.5rem 1fr auto 8rem 0 0;
}

body:has([data-sidebar-target="sidebar"]:not(.hidden)) .okr-grid>*:nth-last-child(-n+2) {
  overflow: hidden;
  visibility: hidden;
}

/* Additional global body rules to ensure popovers in sidebar are always visible */
body:has([data-sidebar-target="sidebar"]:not(.hidden) .hoverable-popover:not(.hidden)) {
  /* This creates a global stacking context that ensures the fixed popover is always visible */
  overflow: auto;
  /* Add a minimal containing behavior to ensure scrolling still works */
  contain: paint;
}

/* Ensure the popover in sidebar is always above all content, even modals */
/* This uses !important to override any other z-index in the app */
[data-sidebar-target="sidebar"]:not(.hidden) .hoverable-popover:not(.hidden) {
  z-index: 9999 !important;
  /* Use fixed positioning to break out of any overflow constraints */
  position: fixed !important;
}