ul {
  list-style-type: disc;
  /* Or 'none' if you don't want bullet points */
  padding-left: 20px;
  /* Adjust as needed for indentation */
}

ul ul {
  /* For nested lists */
  list-style-type: circle;
  /* Different style for nested lists, optional */
  padding-left: 40px;
  /* Increase indentation for nested lists */
}

.pagy-nav.pagination {
  display: flex;
  gap: 2px;
  /* Tailwind: gap-2 */
}

.pagy-nav.pagination .page {
  padding: 0.25rem 0.5rem;
  /* Tailwind: p-1 px-2 */
  border: 1px solid #d1d5db;
  /* Tailwind: border border-gray-300 */
  border-radius: 0.375rem;
  /* Tailwind: rounded-md */
  cursor: pointer;
}

.pagy-nav.pagination .page:hover {
  background-color: #f3f4f6;
  /* Tailwind: bg-gray-100 */
}

.pagy-nav.pagination .active {
  background-color: #2563eb;
  /* Tailwind: bg-blue-600 */
  color: white;
  /* Tailwind: text-white */
  cursor: default;
}

.pagy-nav.pagination .disabled {
  color: #9ca3af;
  /* Tailwind: text-gray-400 */
  cursor: not-allowed;
}

/* General styling for the Trix toolbar */
#trix-toolbar-1 {
  display: inline-grid;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
}

/* Styling for the buttons within the toolbar */
#trix-toolbar-1 .trix-button {
  color: #555;
  /* Darker text/icons for better contrast */
  background-color: transparent;
  /* No background to keep it clean */
  border: none;
  /* Remove borders */
  font-size: 14px;
  /* Smaller font size for a compact look */
  padding: 5px 8px;
  /* Reduce padding to decrease button size */
  margin: 0 2px;
  /* Minimal margin between buttons */
  border-radius: 3px;
  /* Slightly rounded corners for buttons */
}

/* Hover and active states for buttons */
#trix-toolbar-1 .trix-button:hover,
#trix-toolbar-1 .trix-button.trix-active,
#trix-toolbar-1 .trix-button:focus {
  background-color: #e7e7e7;
  /* Slight background on hover/active for feedback */
  outline: none;
  /* Remove outline to maintain the sleek look */
}

/* Styling for the button groups to add separation */
#trix-toolbar-1 .trix-button-group {
  margin-right: 5px;
  /* Add some space between button groups */
}

/* Styling for the dialogs within the toolbar */
#trix-toolbar-1 .trix-dialog {
  background-color: #fff;
  /* Ensure dialogs have a solid background */
  border: 1px solid #ccc;
  /* Light border for dialogs */
  border-radius: 4px;
  /* Rounded corners for dialogs */
}

#trix-toolbar-1 .trix-dialog .trix-input--dialog {
  border: 1px solid #ccc;
  /* Light border for input fields in dialogs */
  border-radius: 4px;
  /* Rounded corners for input fields */
  padding: 8px;
  /* Comfortable padding inside input fields */
}

#trix-toolbar-1 .trix-dialog .trix-button--dialog {
  background-color: #007bff;
  /* Example primary color for dialog buttons */
  color: #fff;
  /* White text for contrast */
  border-radius: 4px;
  /* Rounded corners for dialog buttons */
  padding: 6px 12px;
  /* Padding inside dialog buttons */
  border: none;
  /* Remove border from dialog buttons */
  margin-top: 8px;
  /* Space above the dialog buttons */
}

/* Flash message transitions */
[data-flash-target="message"] {
  transition: opacity 0.5s ease-out;
  opacity: 1;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* Toast container for multiple messages */
.toast {
  transition: transform 0.15s ease-out;
  max-width: min(calc(100vw - 2rem), 42rem);
  z-index: 50;
  pointer-events: auto;
}

.toast.toast-end {
  transform: translateX(0);
}

.toast.toast-start {
  transform: translateX(0);
}

.toast.toast-center {
  transform: translateY(0);
}

/* Flash message stacking */
.toast> :not(:first-child) {
  margin-top: 0.5rem;
}

/* Ensure flash messages are clickable */
.alert {
  pointer-events: auto;
  cursor: default;
}

/* Flash message button hover states */
.alert button {
  opacity: 0.8;
  transition: opacity 0.15s ease-out;
}

.alert button:hover {
  opacity: 1;
}