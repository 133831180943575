.bg-red-500 {
    background-color: #f56565;
}

.bg-pink-500 {
    background-color: #ed64a6;
}

.bg-purple-500 {
    background-color: #9f7aea;
}

.bg-deep-purple-500 {
    background-color: #673ab7;
}

/* Example, adjust the color */
.bg-indigo-500 {
    background-color: #667eea;
}

.bg-blue-500 {
    background-color: #4299e1;
}

.bg-light-blue-500 {
    background-color: #3b82f6;
}

/* Adjust based on preference */
.bg-cyan-500 {
    background-color: #0bc5ea;
}

.bg-teal-500 {
    background-color: #319795;
}

.bg-green-500 {
    background-color: #48bb78;
}

.bg-light-green-500 {
    background-color: #9ae6b4;
}

/* Example, adjust the color */
.bg-lime-500 {
    background-color: #d9f99d;
}

/* Example, adjust the color */
.bg-yellow-500 {
    background-color: #ecc94b;
}

.bg-amber-500 {
    background-color: #f6e05e;
}

.bg-orange-500 {
    background-color: #ed8936;
}

.bg-deep-orange-500 {
    background-color: #f4511e;
}

/* Example, adjust the color */
.bg-brown-500 {
    background-color: #a1887f;
}

/* Example, adjust the color */
.bg-blue-grey-500 {
    background-color: #78909c;
}

/* Example, adjust the color */

.text-white {
    color: #ffffff;
}